define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-topic-page-class", ["exports", "@ember/component", "@ember/runloop", "discourse/plugins/discourse-post-voting/discourse/initializers/post-voting-edits"], function (_exports, _component, _runloop, _postVotingEdits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "",
    didInsertElement() {
      this._super(...arguments);
      this._refreshClass();
      this.appEvents.on("post-voting-topic-updated", this, this._updateClass);
    },
    willDestroyElement() {
      this._super(...arguments);
      this._removeClass();
      this.appEvents.off("post-voting-topic-updated", this, this._updateClass);
    },
    _refreshClass() {
      (0, _runloop.scheduleOnce)("afterRender", this, this._updateClass);
    },
    _updateClass() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      const body = document.getElementsByTagName("body")[0];
      this._removeClass();
      if (this.topic.postStream.filter === _postVotingEdits.ORDER_BY_ACTIVITY_FILTER) {
        body.classList.add("post-voting-topic-sort-by-activity");
      } else {
        body.classList.add("post-voting-topic");
      }
    },
    _removeClass() {
      const body = document.getElementsByTagName("body")[0];
      body.classList.remove("post-voting-topic");
      body.classList.remove("post-voting-topic-sort-by-activity");
    }
  });
});