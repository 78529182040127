define("discourse/plugins/discourse-post-voting/discourse/templates/connectors/topic-above-post-stream/add-post-voting-topic-page-class", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.is_post_voting}}
    {{post-voting-topic-page-class topic=model}}
  {{/if}}
  */
  {
    "id": "alSwc4wI",
    "block": "[[[41,[30,0,[\"model\",\"is_post_voting\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"topic\"],[[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-post-voting/discourse/templates/connectors/topic-above-post-stream/add-post-voting-topic-page-class.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-post-voting/discourse/templates/connectors/topic-above-post-stream/add-post-voting-topic-page-class.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"post-voting-topic-page-class\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/templates/connectors/topic-above-post-stream/add-post-voting-topic-page-class.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});